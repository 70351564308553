/* eslint-disable import/no-named-as-default */
import React, { Suspense, useRef } from 'react'

import { useEditor } from '@tiptap/react'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import { useQuery } from '@tanstack/react-query'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import SubScript from '@tiptap/extension-subscript'
import TextAlign from '@tiptap/extension-text-align'
import Superscript from '@tiptap/extension-superscript'
import { useNavigate, useParams } from 'react-router-dom'
import { IconEdit, IconFileTypePdf } from '@tabler/icons-react'
import { Button, Container, Fieldset, Table, Text } from '@mantine/core'

// import { BlockType } from '@customTypes/block'
// import { ReceiptType } from '@customTypes/receipt'
import { ProformaBaseType } from '@customTypes/proforma'

import DataRepo from '@api/datasource/data'

import QueryKeys from '@constants/queryKeys'
import { RoutesApp } from '@constants/routes'
import { StatusProforma, Entities, DueDateOptions } from '@constants/proforma'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { $ } from '@utils/styles'
import { ErrorService } from '@utils/error'
import { buildSelectOptions } from '@utils/form'
import { generatePDF, getDescription, getProformaTotal } from '@utils/proforma'
import { isLoadingOrRefetchQuery } from '@utils/network'

import Input from '@components/shared/input'
import LoaderText from '@components/shared/loader'
import NumberFormat from '@components/shared/Number'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import { notifications } from '@mantine/notifications'
import { useStoreBase } from '@store/index'

//import TextEditor from '@components/shared/richTextEditor'

const TextEditor = React.lazy(() => import('@components/shared/richTextEditor'))

//import PDF from '@components/proforma/pdf'
const PDF = React.lazy(() => import('@components/proforma/pdf'))

type Mode = 'create' | 'edit'

const PreviewProforma = () => {
  const navigate = useNavigate()

  const { user } = useStoreBase()

  const { uid } = useParams<{ uid: string }>()

  const mode: Mode = uid ? 'edit' : 'create'

  const isMobile = useIsMobile()

  const minWidth = useTableMinWidth(isMobile)

  const pdfRef = useRef(null)

  const [generatingPDF, setGeneratingPDF] = React.useState(false)

  const proformaDataQuery = useQuery<ProformaBaseType, ErrorService, ProformaBaseType, string[]>({
    enabled: mode === 'edit',
    queryKey: [QueryKeys.GET_PROFORMA_KEY, String(uid)],
    queryFn: async ({ queryKey }) => {
      const response = await DataRepo.documentEntitiesService.getProformaById(queryKey[1])

      return response
    },
  })

  const totalProformaQuery = useQuery<
    [number, number, number],
    ErrorService,
    [number, number, number],
    [string, ProformaBaseType['items'], number]
  >({
    initialData: [0, 0, 0],
    refetchOnMount: true,
    queryKey: [
      QueryKeys.GET_PROFORMA_TOTAL_KEY,
      proformaDataQuery.data?.items ?? [],
      proformaDataQuery.data?.iva ?? 0,
    ],
    queryFn: async ({ queryKey }) => {
      const [, items, iva] = queryKey
      let total = 0

      for (const item of items) {
        total += getProformaTotal({
          item,
          itemsProforma: items,
        })
      }

      return [total, total * (iva / 100), total * (1 + iva / 100)]
    },
  })

  const editorDescription = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    editable: false,
    content: proformaDataQuery.data?.description ?? '',
  })

  React.useEffect(() => {
    if (editorDescription && proformaDataQuery.data?.description) {
      // const adicionalDescription = proformaDataQuery.data.items.reduce((acc, item) => {
      //   if ([Entities['receipt'].value, Entities['block'].value].includes(item.type)) {
      //     const descriptionProforma = (item as ReceiptType | BlockType).descriptionProforma
      //     if (descriptionProforma) {
      //       acc += `<p><strong>${item.name}:</strong></p>${descriptionProforma}`
      //     }
      //   }
      //   return acc
      // }, '')
      // editorDescription.commands.setContent(
      //   proformaDataQuery.data.description + adicionalDescription,
      // )
      editorDescription.commands.setContent(proformaDataQuery.data.description)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proformaDataQuery.data?.description])

  React.useEffect(() => {
    if (!generatingPDF) return

    if (!proformaDataQuery.data) {
      notifications.show({
        color: 'red',
        title: 'Error al generar PDF',
        message: 'No se pudo obtener la información de la proforma',
      })
      return
    }

    if (!pdfRef.current) {
      notifications.show({
        color: 'red',
        title: 'Error al generar PDF',
        message: 'No se pudo obtener la referencia del PDF',
      })
      return
    }

    setTimeout(() => {
      generatePDF(pdfRef, proformaDataQuery.data.name).finally(() => {
        setGeneratingPDF(false)
      })
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatingPDF])

  const isLoading = isLoadingOrRefetchQuery(proformaDataQuery)

  return (
    <React.Fragment>
      {!isLoading && (
        <React.Fragment>
          <Container
            className="cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]"
            size={isMobile ? 'xl' : '90%'}
          >
            <div className="cd-flex cd-justify-end cd-gap-x-[1rem]">
              <Button
                leftSection={<IconEdit size={20} />}
                onClick={() => {
                  navigate(RoutesApp.PROFORMA_FORM.replace(':uid', String(uid)))
                }}
              >
                Editar
              </Button>
              {user?.role === 'admin' && (
                <Button
                  color="green"
                  leftSection={<IconFileTypePdf size={22} />}
                  loading={generatingPDF}
                  onClick={() => {
                    setGeneratingPDF(true)
                  }}
                >
                  Exportar
                </Button>
              )}
            </div>
            <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem] cd-mt-[1rem]">
              <Fieldset className="cd-flex cd-flex-col cd-gap-y-[0.5rem]" legend="Proforma">
                <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem] md:cd-flex-row md:cd-gap-x-[1rem]">
                  <Input
                    readOnly
                    className="cd-basis-[100%] md:cd-basis-[60%]"
                    label="Nombre"
                    typeInput="text"
                    value={proformaDataQuery.data?.name}
                    variant="filled"
                  />
                  <Input
                    readOnly
                    className={$('cd-basis-[100%] md:cd-basis-[20%]')}
                    data={buildSelectOptions({
                      data: Object.values(StatusProforma),
                      label: 'label',
                      value: 'value',
                    })}
                    label="Estado"
                    rightSection={<React.Fragment />}
                    typeInput="select"
                    value={proformaDataQuery.data?.status}
                    variant="filled"
                  />
                  <Input
                    readOnly
                    className="cd-basis-[100%] md:cd-basis-[20%]"
                    label="Secuencial"
                    typeInput="text"
                    value={proformaDataQuery.data?.sequential}
                    variant="filled"
                  />
                </div>
              </Fieldset>

              <Fieldset className="cd-flex cd-flex-col cd-gap-y-[0.5rem]" legend="Cliente">
                <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem] md:cd-flex-row md:cd-gap-x-[1rem]">
                  <Input
                    readOnly
                    className="cd-basis-[100%] md:cd-basis-1/3"
                    label="Nombre"
                    typeInput="text"
                    value={proformaDataQuery.data?.client.name}
                    variant="filled"
                  />
                  <Input
                    readOnly
                    className="cd-basis-[100%] md:cd-basis-2/3"
                    label="Ubicación"
                    typeInput="text"
                    value={proformaDataQuery.data?.client.location}
                    variant="filled"
                  />
                </div>
                <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem] md:cd-flex-row md:cd-gap-x-[1rem]">
                  <Input
                    readOnly
                    className="cd-basis-[100%] md:cd-basis-1/3"
                    label="RUC"
                    typeInput="text"
                    value={proformaDataQuery.data?.client.ruc}
                    variant="filled"
                  />
                  <div className="cd-basis-[100%] md:cd-basis-2/3 cd-flex cd-flex-col cd-gap-y-[0.5rem] md:cd-flex-row md:cd-gap-x-[1rem]">
                    <Input
                      readOnly
                      className="cd-basis-[100%] md:cd-basis-1/2"
                      label="Email"
                      typeInput="text"
                      value={proformaDataQuery.data?.client.email}
                      variant="filled"
                    />
                    <Input
                      readOnly
                      className="cd-basis-[100%] md:cd-basis-1/2"
                      label="Teléfono"
                      typeInput="text"
                      value={proformaDataQuery.data?.client.phone}
                      variant="filled"
                    />
                  </div>
                </div>
                <Input
                  readOnly
                  className="cd-basis-[100%] md:cd-basis-1/3"
                  label="IVA (%)"
                  suffix="%"
                  typeInput="number"
                  value={proformaDataQuery.data?.iva}
                  variant="filled"
                />
              </Fieldset>

              <Fieldset legend="Items">
                <TableCustom<ProformaBaseType['items'][0]>
                  alwaysHeader
                  hidePagination
                  columns={buildColumns()}
                  data={proformaDataQuery.data?.items}
                  extraRows={buildExtraRows()}
                  keyId="uid"
                  limitPage={1000}
                  minWidth={minWidth}
                  noDataMessage="Agregue items a la proforma"
                />
              </Fieldset>

              <Fieldset className="cd-flex cd-flex-col cd-gap-y-[0.5rem]" legend="Pagos">
                {proformaDataQuery.data?.payments.map((payment, idx) => (
                  <div
                    className="cd-flex cd-flex-col md:cd-flex-row cd-gap-x-[1rem] cd-gap-y-[0.5rem] md:cd-gap-y-[1rem]"
                    key={`payment-${idx}`}
                  >
                    <Input
                      readOnly
                      className="cd-basis-[100%] md:cd-basis-[70%]"
                      label={`Descripción del pago #${idx + 1}`}
                      placeholder="Ingrese la descripción"
                      typeInput="text"
                      value={payment.text}
                      variant="filled"
                    />
                    <Input
                      readOnly
                      className="cd-basis-[100%] md:cd-basis-[30%]"
                      label="Porcentaje"
                      placeholder="Ingrese el porcentaje"
                      suffix="%"
                      typeInput="number"
                      value={payment.percentage}
                      variant="filled"
                    />
                  </div>
                ))}
              </Fieldset>

              <Fieldset className="cd-flex cd-flex-col cd-gap-y-[0.5rem]" legend="Extras">
                <div className="cd-flex cd-gap-x-[1rem] cd-gap-y-[0.5rem] md:cd-gap-y-[1rem]">
                  <div className="cd-basis-[100%] md:cd-basis-1/2 cd-flex cd-flex-col cd-gap-[1rem] md:cd-flex-row">
                    <Input
                      readOnly
                      className="cd-basis-[100%] md:cd-basis-[70%]"
                      label="Oferta válida hasta"
                      typeInput="number"
                      value={proformaDataQuery.data?.validUntil.amount}
                      variant="filled"
                    />
                    <Input
                      readOnly
                      searchable
                      className="cd-basis-[100%] md:cd-basis-[30%]"
                      clearable={false}
                      data={buildSelectOptions({
                        data: Object.values(DueDateOptions),
                        label: 'label',
                        value: 'value',
                      })}
                      label="Unidad"
                      typeInput="select"
                      value={proformaDataQuery.data?.validUntil.unit}
                      variant="filled"
                    />
                  </div>
                  <div className="cd-basis-[100%] md:cd-basis-1/2 cd-flex cd-flex-col cd-gap-[1rem] md:cd-flex-row">
                    <Input
                      readOnly
                      className="cd-basis-[100%] md:cd-basis-[70%]"
                      label="Fecha de entrega"
                      typeInput="number"
                      value={proformaDataQuery.data?.dueDate.amount}
                      variant="filled"
                    />
                    <Input
                      readOnly
                      searchable
                      className="cd-basis-[100%] md:cd-basis-[30%]"
                      clearable={false}
                      data={buildSelectOptions({
                        data: Object.values(DueDateOptions),
                        label: 'label',
                        value: 'value',
                      })}
                      label="Unidad"
                      typeInput="select"
                      value={proformaDataQuery.data?.dueDate.unit}
                      variant="filled"
                    />
                  </div>
                </div>
                <Input
                  autosize
                  readOnly
                  className="cd-basis-[100%] md:cd-basis-1/3"
                  label="Garantía"
                  typeInput="textarea"
                  value={proformaDataQuery.data?.warranty}
                  variant="filled"
                />

                <Suspense>
                  <TextEditor hideToolbar editor={editorDescription} label="Descripción" />
                </Suspense>
              </Fieldset>
            </div>
          </Container>
          {proformaDataQuery.data && totalProformaQuery.data && (
            <Suspense>
              <PDF
                className="cd-fixed"
                generating={generatingPDF}
                proforma={proformaDataQuery.data}
                ref={pdfRef}
                totals={totalProformaQuery.data}
              />
            </Suspense>
          )}
        </React.Fragment>
      )}
      {isLoading && (
        <LoaderText className="cd-mt-[3rem]">
          <Text c="dimmed" className="cd-text-base">
            Cargando proforma
          </Text>
        </LoaderText>
      )}
    </React.Fragment>
  )

  function buildExtraRows() {
    return (
      <React.Fragment key="extra-fragment">
        <Table.Tr key="subtotal-row">
          <Table.Td colSpan={4} rowSpan={3} />
          <Table.Td className="cd-font-bold">Subtotal</Table.Td>
          <Table.Td className="cd-font-bold cd-text-right">
            <NumberFormat value={totalProformaQuery.data?.[0] ?? 0} />
          </Table.Td>
        </Table.Tr>
        <Table.Tr key="iva-row">
          <Table.Td className="cd-font-bold">IVA ({proformaDataQuery.data?.iva ?? 0}%)</Table.Td>
          <Table.Td className="cd-font-bold cd-text-right">
            <NumberFormat value={totalProformaQuery.data?.[1] ?? 0} />
          </Table.Td>
        </Table.Tr>
        <Table.Tr key="total-row">
          <Table.Td className="cd-font-bold">Total</Table.Td>
          <Table.Td className="cd-font-bold cd-text-right">
            <NumberFormat value={totalProformaQuery.data?.[2] ?? 0} />
          </Table.Td>
        </Table.Tr>
      </React.Fragment>
    )
  }

  function buildColumns() {
    return [
      {
        key: 'uid',
        label: 'ID',
        type: 'calc',
        width: '10%',
        defaultOnClick: true,
        render: ({ uid, type }) => type !== 'accessory' && uid,
      },
      {
        key: 'type',
        label: 'Tipo',
        type: 'calc',
        width: '7%',
        defaultOnClick: true,
        render: ({ type }) => Entities[type].label,
      },
      {
        key: 'name',
        label: 'Nombre',
        width: '15%',
        type: 'calc',
        defaultOnClick: true,
        render: ({ name }) => name,
      },
      {
        key: 'description',
        label: 'Descripción',
        width: '30%',
        type: 'calc',
        defaultOnClick: true,
        render: (item) =>
          getDescription({
            userRole: user?.role ?? '',
            item,
          }),
      },
      {
        key: 'quantity',
        label: 'Cantidad',
        width: '10%',
        type: 'calc',
        align: 'right',
        render: (item) => <NumberFormat prefix="" value={item.quantity} />,
      },
      {
        key: 'value',
        label: 'Valor',
        width: '10%',
        type: 'calc',
        align: 'right',
        render: (item) => (
          <NumberFormat
            value={getProformaTotal({
              itemsProforma: proformaDataQuery.data?.items ?? [],
              unit: true,
              item,
            })}
          />
        ),
      },
      {
        key: 'total',
        label: 'Total',
        width: '10%',
        type: 'calc',
        align: 'right',
        render: (item) => (
          <NumberFormat
            value={getProformaTotal({
              itemsProforma: proformaDataQuery.data?.items ?? [],
              item,
            })}
          />
        ),
      },
    ] as GenericColumnType<ProformaBaseType['items'][0]>[]
  }
}

export default PreviewProforma
